<template>
  <footer class="footer p-0">
      <b-button class="rounded-circle p-1 srcoll-up-btn" @click="scrollTop()" v-if="isActivebtn">
        <unicon name="angle-up" fill="#fff" width="24" height="24" />
      </b-button>
      <div class="footer-container">
        <b-row class="h-100" style="min-height: 261px;">
          <b-col cols="12" order="2" lg="4">
            <div class="mx-auto text-white pt-5" style="max-width: 260px"  data-aos="fade-in" data-aos-duration="5000">
              <h4 class="text-center text-white">
                <strong>{{t('footer').map}}</strong>
              </h4>
              <ul class="list-unstyled p-0 m-0">
                <li class="d-flex justify-content-around py-1">
                  <router-link class="px-2" v-if="$route.name != 'website'" to="/"> {{ t('nav').home }} </router-link>
                  <span class="px-2" style="cursor: pointer" v-else @click="scrollTop()"> {{ t('nav').home }} </span>
                  <router-link class="px-2" to="/about"> {{ t('nav').aboutus }} </router-link>
                </li>
                <li class="d-flex justify-content-around py-1">
                  <router-link class="px-2" to="/services"> {{ t('nav').services }} </router-link>
                  <router-link class="px-2" to="/products"> {{ t('nav').products }} </router-link>
                </li>
                <li class="d-flex justify-content-around py-1">
                  <router-link class="px-2" to="/activities"> {{ t('nav').activities }} </router-link>
                  <router-link class="px-2" to="/contact"> {{ t('nav').contactus }} </router-link>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col cols="12" order="3" lg="4">
            <div class="mx-auto pt-5 text-white" style="max-width: 260px" data-aos="fade-in" data-aos-duration="5000">
              <h4 class="text-center text-white">
                <strong>{{t('footer').contactus}}</strong>
              </h4>
              <ul class="list-unstyled p-0 m-0">
                <li>
                  <ul class="list-unstyled flex-column-reverse flex-md-row p-0 m-0 d-flex justify-content-around">
                    <li class="d-flex justify-content-around p-1">
                      <span class="pr-50">
                        <unicon name="phone-alt" fill="#fff" />
                      </span>
                      <span class="px-50 text-nowrap">{{ companyWebsiteDto.phoneNumber }}</span>
                    </li>
                    <li class="d-flex justify-content-around p-1">
                      <span class="pr-50">
                        <unicon name="envelope" fill="#fff" />
                      </span>
                      <a :href="'mailto:' + companyWebsiteDto.email">
                        <span class="text-nowrap">{{ companyWebsiteDto.email }}</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul class="list-unstyled flex-column-reverse flex-md-row p-0 m-0 d-flex justify-content-around">
                    <li class="d-flex justify-content-around p-1">
                      <span class="pr-50">
                        <unicon name="tablet" fill="#fff" />
                      </span>
                      <a :href="'tel:' + companyWebsiteDto.mobileNumber">
                        <span class="px-50 text-nowrap">{{ companyWebsiteDto.mobileNumber }}</span>
                      </a>
                    </li>
                    <li class="d-flex justify-content-center p-1">
                      <span class="pr-50">
                        <unicon name="facebook-f" fill="#fff" />
                      </span>
                      <a :href="'https://' + companyWebsiteDto.facebookLink">
                        <span class="text-nowrap">{{ companyWebsiteDto.facebookLink }}</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col cols="12" order="1" order-lg="3" lg="4" class="logo-col">
            <div class="logo pr-3" data-aos="fade-right" data-aos-duration="5000">
              <img src="/images/logo/logo.svg" width="200">
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mini-footer">
        <div class="footer-copyright">
            <div class="container overflow-hidden px-0 flex-column-reverse flex-md-row d-flex align-items-center justify-content-between">
                <div class="copyright-txt">
                    <span class="pr-50"> Al Faisal </span>
                    <span> {{new Date().getFullYear()}} {{t('footer').rights}} </span>
                </div>
                <div class="dolphen px-0   flex-md-row d-flex align-items-center justify-content-between">
                    <p class="my-0 mr-1">{{t('footer').dolphen}}</p>   
                    <a style="width:80px" href="https://www.dolphingroup-co.com" target="_blank">
                        <img src="/images/website/Dolphin-LOGO-03-03.png"
                             class="img-fluid" />
                    </a>
                </div>
              
            </div>
        </div>
      </div>
      <div class="powered">
        <p>{{t('footer').powered}}</p>
        <a href="https://www.elkood.com" target="_blank">
          <img
           
            src="/images/website/elkood-white.svg"
            class="img-fluid"
          />
        </a>
      </div>
  </footer>
</template>

<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { mapGetters } from 'vuex'
export default {
 computed: {
   ...mapGetters(['companyWebsiteDto'])
 },
 data: () => ({
   isActivebtn: false
 }),
  setup() {
      const { t } = useI18nUtils();
      return { t }
  },
  methods: {
    scrollTop() {
      scrollTo(0, 0)
    },
    isActive(){
      this.isActivebtn = window.scrollY > 1000
    }
  },
  mounted() {
    addEventListener('scroll', this.isActive)
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables/variables.scss";
.footer {
  padding-top: 100px;
  overflow: hidden;
  .footer-container {
    background: $primary;
    span {
      font-size: 16px;
    }
    .logo-col {
      position: relative;
      height: 100%;
      .logo {
        width: 100%;
        height: 300px;
        background: $secondaryPrimary;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 60px;
        }
      }
    }
    @media screen and (min-width: 992px) {
      .logo-col {
        position: relative;
        height: 100%;
        .logo {
          width: 330px;
          height: 321px;
          background: $secondaryPrimary;
          position: absolute;
          top: -60px;
          right: -54px;
          border-radius: 14px;
          transform: skewX(-17deg);
          border: solid 5px #fff;
          border-bottom: none;
          border-bottom-left-radius: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 60px;
            transform: skewX(17deg)
          }
          img {
            transform: skewX(17deg);
          }
        }
      }
    }
  }
  a {
    color: #fff;
    font-size: 16px;
    &:hover {
      color: $secondaryPrimary;
    }
  }
  .mini-footer {
    background: $primary;
    padding: 12px 16px;
    border-top: solid 4px #fff;
    .footer_contact-us a {
      text-decoration: none;
      color: #fff;
    }
    .copyright-txt {
      display: inline-flex;
      float: left;
    }
    .footer-copyright .copyright-txt span {
      font-size: 14px;
      color: #ffffff;
    }
    .dolphen {
      font-size: 14px;
      color: #ffffff;
    }
  }
  .powered {
    background: $secondaryPrimary;
    display: flex;
    padding: 12px;
    justify-content: center;
    width: 100%;
    float: right;
  }
  .powered p {
    color: white;
    align-self: center;
    margin: 0 10px;
  }
  .powered img {
    width: 80px;
    margin-bottom: 5px;
  }
  [dir="ltr"] {
    .copyright-txt {
      direction: rtl!important;
    }
    @media screen and (min-width: 992px) {
      .logo-col {
        .logo {
          img {
            transform: skewX(-17deg);
          }
        }
      }
    }
  }
}
.srcoll-up-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 100;
  background: $secondaryPrimary!important;
  border: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"py-1 w-100 position-fixed",attrs:{"toggleable":"lg","type":"dark","variant":_vm.navActive && !_vm.collapse ? 'transparent' : 'primary'}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""},model:{value:(_vm.collapse),callback:function ($$v) {_vm.collapse=$$v},expression:"collapse"}},[_c('b-navbar-nav',{staticClass:"w-100 d-flex align-items-center"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"active":isActive && isExactActive,"href":href},on:{"click":navigate}},[_c('span',{staticClass:"d-inline-block px-2 py-25 navLink",class:{isActive: isActive && isExactActive }},[_vm._v(_vm._s(_vm.t('nav').home))])])]}}])}),_c('router-link',{attrs:{"to":"/about","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',{staticClass:"d-inline-block px-2 py-25 navLink",class:{isActive: isActive }},[_vm._v(_vm._s(_vm.t('nav').aboutus))])])]}}])}),_c('router-link',{attrs:{"to":"/services","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',{staticClass:"d-inline-block px-2 py-25 navLink",class:{isActive: isActive }},[_vm._v(_vm._s(_vm.t('nav').services))])])]}}])}),_c('router-link',{attrs:{"to":"/products","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',{staticClass:"d-inline-block px-2 py-25 navLink",class:{isActive: isActive }},[_vm._v(_vm._s(_vm.t('nav').products))])])]}}])}),_c('router-link',{attrs:{"to":"/activities","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',{staticClass:"px-1 navLink",class:{isActive: isActive }},[_vm._v(_vm._s(_vm.t('nav').activities))])])]}}])}),_c('router-link',{attrs:{"to":"/contact","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',{staticClass:"d-inline-block px-2 py-25 navLink",class:{isActive: isActive }},[_vm._v(_vm._s(_vm.t('nav').contactus))])])]}}])}),_c('locale')],1)],1),_c('img',{staticClass:"mr-lg-3",class:{'mx-auto': _vm.collapse},attrs:{"src":"/images/logo/White.svg","width":"120"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }


<template>
  <b-navbar toggleable="lg" class="py-1 w-100 position-fixed" type="dark" :variant="navActive && !collapse ? 'transparent' : 'primary'">
    <b-container fluid>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse v-model="collapse" id="nav-collapse" is-nav>
        <b-navbar-nav class="w-100 d-flex align-items-center">
          <router-link to="/"
              custom
              v-slot="{ href, navigate, isActive, isExactActive }">
            <a :active="isActive && isExactActive" :href="href" @click="navigate">
              <span class="d-inline-block px-2 py-25 navLink" :class="{isActive: isActive && isExactActive }">{{ t('nav').home }}</span>
            </a>
          </router-link>
          <router-link to="/about"
              custom
              v-slot="{ href, navigate, isActive }">
            <a :active="isActive" :href="href" @click="navigate">
              <span class="d-inline-block px-2 py-25 navLink" :class="{isActive: isActive }">{{ t('nav').aboutus }}</span>
            </a>
          </router-link>
          <router-link to="/services"
              custom
              v-slot="{ href, navigate, isActive }">
            <a :active="isActive" :href="href" @click="navigate">
              <span class="d-inline-block px-2 py-25 navLink" :class="{isActive: isActive }">{{ t('nav').services }}</span>
            </a>
          </router-link>
          <router-link to="/products"
              custom
              v-slot="{ href, navigate, isActive }">
            <a :active="isActive" :href="href" @click="navigate">
              <span class="d-inline-block px-2 py-25 navLink" :class="{isActive: isActive }">{{ t('nav').products }}</span>
            </a>
          </router-link>
          <router-link to="/activities"
              custom
              v-slot="{ href, navigate, isActive }">
            <a :active="isActive" :href="href" @click="navigate">
              <span class="px-1 navLink" :class="{isActive: isActive }">{{ t('nav').activities }}</span>
            </a>
          </router-link>
          <router-link to="/contact"
              custom
              v-slot="{ href, navigate, isActive }">
            <a :active="isActive" :href="href" @click="navigate">
              <span class="d-inline-block px-2 py-25 navLink" :class="{isActive: isActive }">{{ t('nav').contactus }}</span>
            </a>
          </router-link>
          <locale />
        </b-navbar-nav>
      </b-collapse>
      <img src="/images/logo/White.svg" class="mr-lg-3" :class="{'mx-auto': collapse}" width="120">
    </b-container>
  </b-navbar>
</template>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
.navbar {
  transition: all .4s linear;
  z-index: 10000;
}
    @media  (max-width: 991px) {
        ul.navbar-nav a {
            margin: 0 0 10px 0 !important;
        }
        ul.navbar-nav div {
            margin: 0 0 10px 0 !important;
        }
    }
   
    .navbar-toggler.not-collapsed div {
        display:none;
    }
    .isActive,
    .navLink {
        color: #fff !important;
        font-size: 18px;
        opacity: .6;
    }
.isActive, .navLink:hover {
  opacity: 1;
  color: $secondaryPrimary!important;
}
</style>
<script>
import locale from "@core/layouts/components/app-navbar/components/Locale.vue"
import { mapGetters } from 'vuex'
import { useUtils as useI18nUtils } from "@core/libs/i18n";
export default {
  components: {
    locale
  },
  data: () => ({
    collapse: false
  }),
  computed: {
    ...mapGetters(['navActive'])
  },
  setup() {
      const { t } = useI18nUtils();
      return { t }
  }
}
</script>
<template>
  <div>
    <loadingWebsite v-if="isLoading" />
    <navbar />
    <div :style="$route.name != 'website' ? 'padding-top: 52px;' : ''">
      <transition
        :name="'fade-bottom'"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </div>
    <alf-footer />
  </div>
</template>
<script>
import navbar from "@/views/website/layout/navbar"
import alfFooter from "@/views/website/layout/alf-footer";
import loadingWebsite from "@/views/website/layout/loading-website.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    navbar,
    alfFooter,
    loadingWebsite
  },
  computed: {
      ...mapGetters({
          isLoading: "app/isLoadingWebsite"
      })
  },
  created() {
    this.getContactInfoForWebsite()
    this.fetchSettings()
  },
  methods: {
    ...mapActions(["getContactInfoForWebsite", "fetchSettings"])
  }
}
</script>
